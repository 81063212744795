import { Canvas } from "@react-three/fiber";
import { Suspense, lazy, useRef } from "react";
import { Overlay } from "./Overlay";
import Hero from "./Hero";
// import { FiCommand } from "react-icons/fi";
import React from "react";
import styled, { keyframes } from "styled-components";
import Spinner from "./assets/images/metalSpinner.png";

const Scene = lazy(() => import("./Scene"));

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Scroller = ({ children }) => {
  const scroll = useRef(0);

  React.useEffect(() => {
    const onScroll = () => {
      scroll.current =
        window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight);
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return children(scroll);
};

const Home = () => {
  return (
    <Container>
      {/* <SpinnerImg src={Spinner} aria-hidden="true" alt="Loading spinner" /> */}
      <SpinnerSpan className="loader"></SpinnerSpan>
      <CanvasContainer>
        <Canvas
          shadows
          flat
          linear
          aria-hidden="true"
          performance={{
            min: 0.4,
            max: 1,
            debounce: 200,
          }}
        >
          <Scroller>
            {(scroll) => (
              <Suspense>
                <Scene scroll={scroll} />
              </Suspense>
            )}
          </Scroller>
        </Canvas>
      </CanvasContainer>
      <Content>
        <Overlay />
      </Content>
    </Container>
  );
};

const Content = styled.div`
  // Creates a new stacking context
  position: relative;
  /* padding-inline: 10px; */
  /* padding-top: 60px; */

  @media screen and (min-width: 1272px) {
    /* padding-top: 120px; */
    /* padding-left: 120px; */
  }
`;

const Container = styled.div`
  /* position: relative; */
  /* max-width: 100%; */
`;

const CanvasContainer = styled.div`
  position: fixed;
  inset: 0;
`;

const SpinnerImg = styled.img`
  position: fixed;
  width: 50px;
  height: 50px;
  right: 20%;
  top: 50%;
  translate: 0 -50%;
  animation-name: ${spin};
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (max-width: 1272px) {
    right: 50%;
    translate: 50%;
    bottom: 20%;
    top: auto;
    max-width: 100vw;
  }
`;

const SpinnerSpan = styled.span`
  background: linear-gradient(
    165deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(220, 220, 220) 40%,
    rgb(170, 170, 170) 98%,
    rgb(10, 10, 10) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  opacity: 0.8;
  width: 50px;
  height: 50px;
  margin-top: 40vh;
  margin-left: 35vw;
  position: absolute;
  /* right: 25%; */
  /* top: 40%;
  margin: auto; */

  &:before {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border-bottom: 0 solid #ffffff05;
    box-shadow: 0 -10px 20px 20px #ffffff40 inset,
      0 -5px 15px 10px #ffffff50 inset, 0 -2px 5px #ffffff80 inset,
      0 -3px 2px #ffffffbb inset, 0 2px 0px #ffffff, 0 2px 3px #ffffff,
      0 5px 5px #ffffff90, 0 10px 15px #ffffff60, 0 10px 20px 20px #ffffff40;
    filter: blur(3px);
    animation: 2s rotate linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 800px) {
    margin-top: 25vh;
    margin-left: 25vw;
  }
`;

export default Home;
