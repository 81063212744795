import styled, { keyframes } from "styled-components";
import AutoPlay from "./AutoPlay";
import portraitSmall from "./assets/images/portraitSmall.jpg";
import React, { useState, useEffect } from "react";
import { tech_skills } from "./data";

const glitchAnimation1 = keyframes`
    0%,
    100% {
      opacity: 1;
      transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }

    20% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }

    30% {
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }

    40% {
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }

    50% {
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }

    55% {
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }

    60% {
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }

    65% {
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }

    70% {
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }

    80% {
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }

    85% {
      clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
    }

    95% {
      clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
    }
  `;

const glitchAnimation2 = keyframes`
0%,
    100% {
      opacity: 1;
      transform: translate3d(-10px, 0, 0);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }

    10% {
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }

    15% {
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }

    17% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }

    19% {
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }

    33% {
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }

    35% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }

    40% {
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }

    45% {
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }

    49% {
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }

    50% {
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }

    55% {
      clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
    }

    60% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }

    70% {
      clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
    }

    80% {
      clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
    }

    90% {
      clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
    }    
`;

const glitchAnimation3 = keyframes`
0%,
    100% {
      opacity: 1;
      transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }

    5% {
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }

    11% {
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }

    20% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }

    25% {
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }

    35% {
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }

    42% {
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }

    48% {
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }

    50% {
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }

    56% {
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }

    61% {
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }

    68% {
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }

    72% {
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }

    77% {
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }

    81% {
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }

    86% {
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }

    90% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }

    92% {
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }

    94% {
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
`;

const glitchAnimation4 = keyframes`
0%,
    5% {
      opacity: 0.2;
      transform: translate3d(10px, 5px, 0);
    }

    5.5%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
`;

const Bio = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 480;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SectionWrapper>
      <BioDiv>
        <AvatarDiv>
          <div
            style={{ backgroundImage: `url(${portraitSmall})` }}
            className="c-glitch"
          >
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
          </div>
        </AvatarDiv>
        <BioDesWrapper>
          <BioP>
            <p>
              From the first iPod to the groundbreaking AI tools, I’ve always
              had a fascination with how technology reshapes our lives.
            </p>
            <br></br>
            <p>
              That curiousity deepened from childhood to college when I chose to
              study digital creation. Someday, a professor shared a thought that
              has resonated with me ever since:
            </p>
            <br></br>
            <p className="quote">
              The work you’ll do in the future is likely indescribable today.
              Technology evolves so rapidly that new roles will emerge, roles we
              can’t even imagine yet.
            </p>
            <br></br>
            <p>
              At this moment, I realized I was determined to be part of the new
              paradigms introduced by technologies into society.
            </p>
            <br></br>
            <p>
              But innovation is far more than just gadgets or technical
              expertise. It’s a multidimensional force intricately woven into
              historical, social, and cultural contexts.
            </p>
            <br></br>
            <p className="quote">
              The essence of technology is by no means anything technological.
            </p>
            <br></br>
            <p>
              This critical perspective articulated by Heiddeger, in the essay{" "}
              <a
                href="https://monoskop.org/images/4/44/Heidegger_Martin_The_Question_Concerning_Technology_and_Other_Essays.pdf"
                style={{ fontStyle: "italic" }}
              >
                Die Frage nach der Technik (The Question Concerning Technology)
              </a>
              , shaped my academic journey in the Bachelor’s program in Digital
              Media and Communication Studies.
            </p>
            <p>
              Conceived by{" "}
              <span>
                <a href="https://professeurs.uqam.ca/professeur/mondoux.andre/">
                  André Mondoux
                </a>
              </span>
              , PhD, a sociologist with expertise in the societal consequences
              of emerging technologies, particularly in areas such as big data,
              artificial intelligence, and surveillance, the program
              conditionned us (students) to research how these innovations shape
              new forms of social (re)production and governmentality in
              contemporary society.
            </p>
            {/* <p>
              Remember Cambridge Analytica scandal, which surfaced in 2018,
              involved the misuse of personal data from millions of Facebook
              users ? For context, the British political consulting firm,
              Cambridge Analytica, collected this data without consent to create
              targeted political ads during the 2016 U.S. presidential election.
              The company worked for Donald Trump's campaign, using
              psychological profiling to influence voters' behavior. This raised
              concerns over privacy violations, data security, and the ethical
              use of personal data in politics. Let’s just say I wasn’t
              surprised, as it was exactly the kind of research I was doing
              during my bachelor’s. Back in 2015, I conducted a short project
              exploring the growing influence of social media on political
              elections, well before it became a widely discussed issue.
            </p> */}
            <br></br>
            <p>
              I carried this knowledge into my professional life, working across
              various fields, including the cultural, art, fashion, musical,
              commercial, and political industries, where I contributed to
              diverse projects that bridged creativity and strategic impact.
            </p>
            <br></br>
            <p>
              The most stimulating experience was traveling accros the ocean for
              a three months contract at the Chamber of Commerce France-Canada
              in Paris. As social media and communication agent, I had the
              opportunity to work alongside Daniel Jouanneau, French diplomat
              and former Ambassador of France in Canada. This introduction to
              political spheres and international commercial relations allowed
              me to contribute to hosting prestigious conferences at the
              Canadian Embassy in collaboration with the then Ambassador of
              Canada to France, Isabelle Hudon.
            </p>
            <br></br>
            <p>
              Beyond that, I’ve had the privilege of collaborating with over a
              hundred multidisciplinary decision-makers and board members while
              pursuing the most impactful experience of my career through
              initiatives endorsed by the Ministère de l'Immigration, de la
              Francisation et de l'Intégration.
            </p>

            <br></br>
            <p>
              Working with leaders from diverse backgrounds, ages, and
              ethnicities ignited a strong desire to further leverage my
              expertise and become a proactive changemaker.
            </p>
            <br></br>
            <p>
              And this is how I pursued a diploma to become a full-stack
              developer. Equipped with both technical skills and a deep
              awareness of technology’s societal impact, I strive to create
              innovative, consciously unbiased, and sustainable applications
              that truly make a difference.
            </p>
          </BioP>
        </BioDesWrapper>
      </BioDiv>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  .wrapper {
    max-width: 60em;
    margin: 0 auto;
  }

  .scroll-cards {
    counter-reset: card;
    position: relative;
    display: block;
    padding-bottom: 350px;
    /* padding-bottom: 40vh; */

    @media only screen and (max-width: 480px) {
      padding-bottom: 25vh;
    }

    @media only screen and (max-width: 768px) {
      right: 15px;
      width: 85vw;
      /* padding: 0; */
    }

    @media only screen and (min-width: 1600px) {
      padding-bottom: 850px;
    }
  }

  .scroll-cards > .scroll-cards__item + .scroll-cards__item {
    margin: 40vh auto auto auto;
    border-radius: 40px;
  }

  .scroll-cards h1 {
    position: sticky;
    top: 2rem;
    font-size: 2em;
    margin: auto;
    text-align: center;
  }

  .scroll-cards__item {
    --offset: 0.5em;
    color: white;
    position: sticky;
    top: max(16vh, 10em);
    padding: 2em 1.5em;
    min-height: 19em;
    background: #222222;
    background: rgb(27, 27, 27);
    box-shadow: 0px 2px 30px rgba(255, 203, 255, 0.2);
    width: calc(100% - 5 * var(--offset));
    margin: 50vh auto auto auto;
    border-radius: 40px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  h4 {
    font-size: 20px;
    margin-top: 40px;
    font-family: "Poppins", sans-serif;
    display: flex;
    /* justify-content: center; */
    /* margin: auto; */

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  h1 {
    /* font-family: "Poppins", sans-serif; */
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 2;

    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
  }

  .scroll-cards__item:nth-of-type(0) {
    transform: translate(
      calc((0 - 1) * var(--offset)),
      calc((0 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(1) {
    transform: translate(
      calc((1 - 1) * var(--offset)),
      calc((1 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(2) {
    transform: translate(
      calc((2 - 1) * var(--offset)),
      calc((2 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(3) {
    transform: translate(
      calc((3 - 1) * var(--offset)),
      calc((3 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(4) {
    transform: translate(
      calc((4 - 1) * var(--offset)),
      calc((4 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(5) {
    transform: translate(
      calc((5 - 1) * var(--offset)),
      calc((5 - 1) * var(--offset))
    );
  }

  @media screen and (min-width: 37em) {
    h1 {
      font-size: 3em;
    }
    .scroll-cards__item {
      --offset: 1em;
      /* padding-left: 5em; */
      max-width: 42em;
    }
    .scroll-cards__item:before {
      counter-increment: card;
      content: "0" counter(card);
      /* display: flex; */
      display: none;
      align-items: center;
      justify-content: center;
      width: 2.75em;
      height: 2.75em;
      background: linear-gradient(
        to right,
        #b63f95 9.6%,
        #ec6a42,
        #f2b858,
        #92f8dd 93.6%
      );
      color: #fff;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 1.25em;
      top: 1.25em;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 62em) {
    .scroll-cards h1 {
      font-size: 3em;
    }
    .scroll-cards__item {
      --offset: 1.25em;
      max-width: 42em;
    }
  }
`;

const BioDiv = styled.div`
  border-radius: 40px;
  /* background: rgba(122, 122, 122, 0.1);
  backdrop-filter: blur(10px); */
  /* max-width: 800px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  /* align-items: center; */
  /* flex-direction: row-reverse; */

  .c-glitch,
  .c-glitch__img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @media only screen and (max-width: 480px) {
      border-radius: 50%;
      height: 100px;
      width: 100px;
    }
  }

  .c-glitch {
    height: calc(100vh - 200px);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;

    &:hover {
      .c-glitch__img:nth-child(n + 2) {
        animation-duration: 2s;
        animation-delay: 0;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }

      .c-glitch__img:nth-child(2) {
        animation-name: ${glitchAnimation1};
      }

      .c-glitch__img:nth-child(3) {
        animation-name: ${glitchAnimation2};
      }

      .c-glitch__img:nth-child(4) {
        animation-name: ${glitchAnimation3};
      }

      .c-glitch__img:nth-child(5) {
        animation-name: ${glitchAnimation4};
        background-blend-mode: overlay;
        background-color: #af4949;
      }
    }
  }

  .c-glitch__img {
    background-blend-mode: none;
    background-color: transparent;
    height: calc(100% + 5px * 2);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: calc(100% + 10px * 2);

    @media only screen and (max-width: 480px) {
      width: 150px;
      height: 150px;
    }
  }

  .c-glitch__img:nth-child(n + 2) {
    opacity: 0;
  }
`;

const AvatarDiv = styled.div`
  /* border: greenyellow 3px solid; */
  margin: 0;
`;

const TechSDiv = styled.div`
  border-radius: 40px;
  text-align: center;
`;

const TechSTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin: auto auto 20px auto;

  @media only screen and (max-width: 480px) {
    font-size: 30px;
    margin: 4vh auto 8vh auto;
  }
`;

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const BioDesWrapper = styled.div`
  border-radius: 40px;
  background: rgba(50, 50, 50, 0.5);
  padding: 40px;

  @media only screen and (max-width: 768px) {
    background-color: transparent;
    padding: 0;
    width: 100%;
  }
`;

const BioP = styled.p`
  line-height: 36px;

  font-weight: 300;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const TSMobileDiv = styled.div`
  margin-bottom: 8vh;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  height: 120px;

  @media only screen and (max-width: 767px) {
  }
`;

const SkillImage = styled.img`
  max-width: 70px;
  height: auto;
`;

const ToolboxTitle = styled.h2`
  margin-bottom: 10vh;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 35px;
`;

const SSTitle = styled.h3`
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    margin: 50px 0 0 0;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-weight: 300;
    padding: 0;
  }
`;

const CardIcon = styled.span`
  font-size: 50px;
  padding: 0;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    font-size: 60px;
  }
`;

export default Bio;
