import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./locales/translations.json";

i18n.use(initReactI18next).init({
  resources: translations,
  lng: "en", // Default language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
  keySeparator: ".", // This allows you to separate keys by dots (e.g., logo.homepage)
  fallbackLng: "en", // Fallback to 'en' if the language is not available
});

export default i18n;
