import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import heroImage from "./assets/images/heroImage.png";
const Hero = () => (
  <HeroSection aria-labelledby="hero-heading" role="hero">
    <HeroContent>
      <Heading id="hero-heading">
        <div>Innovating with Purpose,</div>
        <div>Leading with Impact.</div>
      </Heading>
      <Subheading>
        {/* Empowering Innovation, Accessibility, and Inclusion */}
      </Subheading>
      {/* <Subheading>
        As a <strong>Full-Stack Developer</strong>,{" "}
        <strong>Digital Strategist</strong>, and{" "}
        <strong>Diversity & Inclusion Consultant</strong>, I specialize in
        creating digital solutions that prioritize{" "}
        <strong>user-centered design</strong>,{" "}
        <strong>web accessibility</strong>, and{" "}
        <strong>inclusive experiences</strong>. My mission is to craft powerful,
        intuitive platforms that not only meet the highest technical standards
        but also empower users from all backgrounds.
      </Subheading> */}
      {/* <CTAButton
        href="#contact"
        role="button"
        aria-label="Contact me to build the future together"
      >
        Let’s Build the Future Together
      </CTAButton> */}
    </HeroContent>
    {/* <div>
      <HeroImg src={heroImage} />
    </div> */}
  </HeroSection>
);

const HeroSection = styled.section`
  background: #1d1d1d;
  color: white;
  text-align: center;
  /* height: 90vh; */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -50px; */
  /* background-color: rgba(13, 15, 17, 255); */
`;

const HeroContent = styled.div`
  margin: auto;
`;

const Heading = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  line-height: 1.5;
  margin: auto;
`;

const HeroImg = styled.img`
  object-fit: cover;
  height: 300px;
`;

const Subheading = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const CTAButton = styled.a`
  padding: 15px 30px;
  background-color: #ff6a00;
  color: white;
  font-size: 1.1em;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e55a00;
  }
`;

export default Hero;
