import styles from "./Overlay.module.css";
import hand3d from "./assets/images/hand3d.png";
import { NavLink, Link } from "react-router-dom";
import AccessibleLink from "./AccessibleLink";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AutoPlay from "./AutoPlay";
import { tech_skills } from "./data";
import human_robot from "./assets/images/human_robot.jpg";
import Hero from "./Hero";

// this is a test
const Emoji = (props) => (
  <span
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);
export default Emoji;

export function Overlay() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 1272;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const { t } = useTranslation();

  return (
    <>
      <OverlayWrapper className="component_wrapper">
        <HeroSection>
          <section
            role="region"
            aria-label="Homepage Hero Section"
            style={{ height: "100svh", minHeight: "100vh" }}
          >
            <HeroDiv class="hero">
              <h1>VSCD</h1>
              <h2>Innovating with Purpose, Leading with Impact.</h2>

              <p className={styles.sronly}>
                Oh hello there ! If you're hearing this message, it means that
                you are a Very Important User ! I wish you a great navigation on
                my portfolio.
              </p>
            </HeroDiv>
            <div style={{ height: "200px" }}></div>
            <HeaderUList>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/fullstack"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to full stack development contributions"
                >
                  full stack development
                </a>
              </HeaderListEl>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/digital-media"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to digital media contributions"
                >
                  digital media
                </a>
              </HeaderListEl>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/communication"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to communication contributions"
                >
                  communication
                </a>
              </HeaderListEl>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/community"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to community contributions"
                >
                  community
                </a>
              </HeaderListEl>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/diversity-inclusion-and-a11y"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to diversity, inclusion & accessibility contributions"
                >
                  diversity, inclusion & a11y
                </a>
              </HeaderListEl>
              <HeaderListEl>
                <a
                  className={styles.gradient_border_button}
                  href="/tech-sociology"
                  class="gradient_border_button"
                  role="button"
                  aria-label="Go to tech sociology contributions"
                >
                  tech sociology
                </a>
              </HeaderListEl>
            </HeaderUList>
          </section>
          <BouncingSvg
            className="bounce"
            width="24"
            height="56.8"
            viewBox="0 0 30 71"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Scroll down</title>
            <rect
              x="1.5"
              y="1.5"
              width="21.6"
              height="36"
              rx="10.8"
              stroke="#cdd1f0"
              strokeOpacity="0.6"
              strokeWidth="2.4"
            />
            <path
              d="M15 9.6V16"
              stroke="#cdd1f0"
              strokeOpacity="0.6"
              strokeWidth="2.4"
              strokeLinecap="round"
            />
            <path
              d="M6.4 43.2L12.1 48.4L17.8 43.2"
              stroke="#cdd1f0"
              strokeOpacity="0.6"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.4 50.4L12.1 55.6L17.8 50.4"
              stroke="#cdd1f0"
              strokeOpacity="0.6"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </BouncingSvg>
        </HeroSection>

        <section
          role="region"
          aria-label="Quick introduction"
          style={{ height: "100svh", minHeight: "100vh" }}
          tabIndex="0"
        >
          <QuickIntro>
            <h3>
              <span aria-hidden="true">🖖🏾 </span>
              <span lang="fr" translate="no">
                Bonjour!
              </span>
              <span lang="en" translate="no">
                {" "}
                Hi!
              </span>
              <span
                lang="bm"
                title="Bambara greeting, meaning 'Hello!'"
                translate="no"
              >
                {" "}
                I ni ce!
              </span>
            </h3>

            <BonjourHi>
              <p>
                I'm V, frontend-oriented full stack dev and digital strategist.
              </p>
              <p>
                Montreal-based, I'm fluent in french, english, HTML/CSS and
                JavaScript.
              </p>
              <p>
                I develop innovative solutions with a multidimensional
                analytical approach and an inclusive mindset.
              </p>
            </BonjourHi>
          </QuickIntro>
        </section>

        <SectionQuote
          role="region"
          aria-label="Mindset"
          style={{ height: "100svh", minHeight: "100vh" }}
          tabIndex="0"
        >
          <ContainerQuote>
            <p>
              Fostering critical thinking of the complex dynamics between
              society and technological advancements, I bring a keen
              <span className="glow"> awareness </span>along with
              <span className="glow"> full stack skills </span>to implement
              conscious and
              <span className="glow"> sustainable </span>
              solutions.
            </p>
          </ContainerQuote>
        </SectionQuote>

        <SectionCTA
          role="region"
          aria-label="Call to Action Section"
          style={{ height: "100svh", minHeight: "100vh" }}
          tabIndex="0"
        >
          <DivCTA className="small_container">
            <p>
              Let's join forces to innovate
              <span className={styles.rocketEm} aria-hidden="true">
                🚀
              </span>
            </p>{" "}
            <AccessibleLink href="/contact" ariaLabel="Go to Contact">
              Contact
            </AccessibleLink>
          </DivCTA>
        </SectionCTA>
      </OverlayWrapper>
    </>
  );
}

const OverlayWrapper = styled.div`
  @media only screen and (max-width: 1272px) {
    /* background-color: green; */
    justify-content: center;
    align-items: center;
    /* width: 87vw; */
    padding: 0;

    /* background-color: pink; */
    /* padding-left: 0; */
  }
`;

const HeroSection = styled.div`
  /* background-color: pink; */
  /* height: 500px; */
`;

const LogoWrapper = styled.div`
  /* top: 5vh;
  left: 7vw; */
  margin-top: 40px;
  margin-left: 100px;
  z-index: 2;
  position: absolute;
  display: none;

  @media only screen and (max-width: 1000px) {
    /* margin-left: 0; */
  }
`;

const Logo = styled.a`
  color: black;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  /* background: rgba(217, 217, 217, 0.9); */

  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  &:hover {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 1272px) {
    font-size: 20px;
    padding: 0;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 1272px) {
    /* width: 30px; */
    height: auto;
  }

  @media only screen and(max-width: 780px) {
    display: none;
  }
`;

const HeroDiv = styled.div`
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 150px;

  h1 {
    margin: auto;
    justify-content: center;
    font-family: "Noto Sans", serif;
  }

  h2 {
    text-align: center;
    font-size: 24px;
    font-family: "Anaheim", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  @media (min-width: 800px) {
    margin-top: 0;
  }
`;

const HeaderUList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin: auto;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 0;

  @media (min-width: 769px) {
    max-width: 70vw;
  }
`;

const HeaderListEl = styled.li`
  text-decoration: none;
  display: none;
  text-align: center;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 769px) {
    display: flex;
    width: auto;
  }
`;

const BonjourHi = styled.div`
  display: block;

  p {
    /* padding-top: 20px; */
  }
`;

const QuickIntro = styled.div`
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  border: 1px solid rgb(184, 199, 212);

  @media (min-width: 800px) {
    /* flex-direction: row; */
  }
  /* max-width: 600px; */
  /* background-color: #edb8ff; */

  /* .intro { */
  /* max-width: 800px;
    padding: 40px; */
  /* } */

  h3 {
    font-size: 40px;
    font-weight: 500;
  }
`;

const BouncingSvg = styled.svg`
  display: flex;
  position: absolute;
  top: calc(100svh - 220px);
  left: 50%;
  margin-left: -10px;
  -webkit-animation: bounce 2s;
  animation: bounce 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  width: 15px;

  @keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-12px);
    }
  }

  @media only screen and (min-width: 800px) {
    display: flex;
    position: absolute;
    top: calc(100svh - 195px);
    -webkit-animation: bounce 2s;
    animation: bounce 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    width: 15px;
  }
`;

const SectionQuote = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContainerQuote = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  /* max-height: 600px; */
  padding: 40px 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;

  backdrop-filter: blur(25px);

  p {
    border-left: solid 2px white;
    padding: 1rem 0 1rem 2rem;

    @media only screen and (min-width: 1898px) {
      padding-top: 0;
      margin: auto;
      border-left: 0;
      /* padding: 0; */
      border-left: solid 2px white;
      padding: 1rem 0 1rem 1.5rem;
    }
  }
`;

const SectionCTA = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  margin: auto;
  text-align: center;
  align-items: center;
  /* height: 400px; */

  @media only screen and (min-width: 800px) {
    height: auto;
    /* padding: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; */
  }
`;

const DivCTA = styled.div`
  p {
    margin-bottom: 40px;
    font-size: var(--font-large);
  }
`;

const TechSDiv = styled.div`
  /* border-radius: 40px; */
  text-align: center;
  width: 100vw;
  margin-left: -100px;
  margin-top: 130px;

  @media only screen and (max-width: 1272px) {
    text-align: center;
    width: 100vw;
    margin-left: -100px;
    margin-top: 130px;
  }
`;
const ToolboxTitle = styled.h2`
  margin-bottom: 100px;
  font-weight: 400;
  font-size: 35px;
`;
