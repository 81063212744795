import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Footer = () => (
  <FooterContainer role="contentinfo" aria-labelledby="footer-heading">
    {/* Footer Heading for Screen Readers */}
    <h2 id="footer-heading" className="visuallyhidden">
      Footer Information
    </h2>

    {/* Footer Navigation */}
    {/* <FooterNav aria-label="Footer Navigation"> */}
    {/* <FooterList>
        <FooterItem>
          <FooterLink
            href="#about"
            title="Learn more about me"
            aria-label="About Section"
          >
            About
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink
            href="#projects"
            title="See my projects"
            aria-label="Projects Section"
          >
            Projects
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink
            href="#contact"
            title="Get in touch with me"
            aria-label="Contact Section"
          >
            Contact
          </FooterLink>
        </FooterItem>
      </FooterList>
    </FooterNav> */}

    {/* Social Media Links */}
    {/* <FooterSection aria-labelledby="social-media">
      <FooterHeading id="social-media">Follow Me</FooterHeading>
      <FooterList className="social-media">
        <FooterItem>
          <FooterLink
            href="https://linkedin.com/in/yourprofile"
            target="_blank"
            title="Follow me on LinkedIn"
            aria-label="LinkedIn Profile"
          >
            LinkedIn
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink
            href="https://github.com/yourprofile"
            target="_blank"
            title="Check out my GitHub"
            aria-label="GitHub Profile"
          >
            GitHub
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink
            href="https://twitter.com/yourprofile"
            target="_blank"
            title="Follow me on Twitter"
            aria-label="Twitter Profile"
          >
            Twitter
          </FooterLink>
        </FooterItem>
      </FooterList>
    </FooterSection> */}

    {/* Contact Info */}
    {/* <FooterSection aria-labelledby="footer-contact">
      <FooterHeading id="footer-contact">Contact Information</FooterHeading>
      <FooterText>
        If you want to collaborate or ask questions, feel free to reach out!
      </FooterText>
      <FooterText>
        Email:{" "}
        <FooterEmailLink
          href="mailto:your.email@example.com"
          aria-label="Send an email to my address"
        >
          your.email@example.com
        </FooterEmailLink>
      </FooterText>
    </FooterSection> */}

    {/* Copyright */}
    <FooterText>&copy; 2024 VSCD All Rights Reserved.</FooterText>
  </FooterContainer>
);

const FooterContainer = styled.footer`
  /* background-color: #2c3e50; */
  color: white;
  padding: 20px;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const FooterNav = styled.nav`
  margin-bottom: 20px;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterItem = styled.li`
  display: inline-block;
  margin: 0 15px;
`;

const FooterLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    outline: 2px solid #f39c12;
  }
`;

const FooterSection = styled.section`
  margin-top: 20px;
`;

const FooterHeading = styled.h3`
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
`;

const FooterEmailLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
`;

export default Footer;
