import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css"; // Global styles
import App from "./App"; // Main App component
import { LanguageProvider } from "./contexts/LanguageContext"; // Language context provider
import "./i18n"; // i18n configuration

// Root element where the React app will be rendered
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Render the app wrapped with the LanguageProvider for global translation support
root.render(
  <StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </StrictMode>
);
