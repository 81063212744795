import React from "react";

const AccessibleLink = ({ href, ariaLabel, children }) => {
  return (
    <a
      href={href}
      aria-label={ariaLabel}
      role="link"
      tabIndex="0" // Ensure the link is focusable and accessible via keyboard
      className="gradient_border_button"
    >
      {children}
    </a>
  );
};

export default AccessibleLink;
