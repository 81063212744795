// import styles from "./About.module.css";
import Bio from "./Bio";
import Education from "./Education";
import Work from "./Work";
import Snippets from "./Snippets";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logoproto from "./assets/images/vscodesportologowhite.png";

const About = () => {
  // conditional renderings
  const [page, setPage] = useState("bio");

  const handleButtonClick = (newPage) => {
    setPage(newPage);
  };

  const handleKeyboardInteraction = (event, newPage) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setPage(newPage);
    }
  };

  return (
    <PageWrapper className="component_wrapper">
      <NavLink
        to="/"
        role="navigation"
        aria-label="Navigate to Home Page"
      ></NavLink>
      <BlobContainer>{/* <div className="shape-blob"></div> */}</BlobContainer>
      {/* <div className="shape-blob one"></div> */}
      {/* <div className="shape-blob two"></div> */}
      <TitleDiv>
        <Title>about</Title>
      </TitleDiv>
      <AboutWrapper>
        <SubMenuDiv>
          <button
            className="btn"
            onClick={() => handleButtonClick("bio")}
            onKeyDown={(e) => handleKeyboardInteraction(e, "bio")}
            aria-label="Go to Bio section"
            role="button"
          >
            <SpanBtn>Bio</SpanBtn>
          </button>
          <button
            className="btn"
            onClick={() => handleButtonClick("snippets")}
            onKeyDown={(e) => handleKeyboardInteraction(e, "snippets")}
            aria-label="Go to Snippets section"
            role="button"
          >
            <SpanBtn>Snippets</SpanBtn>
          </button>
        </SubMenuDiv>
        {(() => {
          switch (page) {
            case "bio":
              return <Bio />;
            case "work":
              return <Work />;
            case "education":
              return <Education />;
            case "snippets":
              return <Snippets />;
          }
        })()}
      </AboutWrapper>
    </PageWrapper>
  );
};

const BlobContainer = styled.div`
  position: fixed;
  pointer-events: none;
  inset: 0;
`;

const PageWrapper = styled.div`
  .shape-blob {
    /* background: linear-gradient(#b63f95, #ec6a42, #92f8dd); */
    background-image: linear-gradient(
      62deg,
      rgba(142, 197, 252, 0.25),
      rgba(224, 195, 252, 0.25)
    );
    height: 1000px;
    width: 1000px;
    border-radius: 30% 50% 20% 40%;
    animation: border-radius 20s ease-in-out infinite both alternate,
      movement_two 40s ease-in-out infinite both;
    opacity: 0.9;
    z-index: -1;
    filter: blur(3rem);
    position: absolute;
    left: 20%;
    top: 70%;
    will-change: transform;
  }

  .shape-blob.one {
    height: 500px;
    width: 500px;
    left: -100px;
    top: -50px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
  }

  .shape-blob.two {
    height: 500px;
    width: 500px;
    left: 1000px;
    top: 50px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;

    @media only screen and (min-width: 2000px) {
      height: 1500px;
      width: 1500px;
      left: 500;
      top: 20%;
    }
  }

  @keyframes border-radius {
    0%,
    100% {
      border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
    }
    20% {
      border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
    }
    40% {
      border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
    }
    60% {
      border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
    }
    80% {
      border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
    }
  }

  @keyframes movement_two {
    0%,
    500% {
      transform: none;
    }
    50% {
      transform: translate(50%, 20%) rotate(-200deg) scale(1.5);
    }
  }
`;

const AboutWrapper = styled.div`
  /* border: greenyellow solid 3px; */
  /* max-width: 1000px; */
  align-items: center;
  margin: auto;
  /* padding: 20px 40px; */
  /* background: rgba(122, 122, 122, 0.07); */
  backdrop-filter: blur(5px);
  border-radius: 50px;
`;

const TitleDiv = styled.div`
  border-radius: 40px;
  /* max-width: 1000px; */
  display: flex;
  margin: auto;
  /* background-color: pink; */

  @media only screen and (min-width: 800px) {
    /* height: 80vh; */
  }
`;

const Title = styled.h1`
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  font-size: 300px;
  font-weight: 500;
  padding: 0;
  margin: auto;
  align-items: center;
  display: flex;
  text-align: center;

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media only screen and (min-width: 800px) {
    font-size: 250px;
  }

  @media only screen and (max-width: 950px) {
    font-size: 100px;
  }
`;

const SubMenuDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto 15px auto;

  button.btn {
    height: 40px;
    margin: 0 20px;
  }

  @media only screen and (max-width: 767px) {
    flex-wrap: nowrap;
    button.btn {
      /* width: 200px; */
      height: 40px;
    }
  }
`;

const LogoDiv = styled.div`
  top: -10px;
  left: 7vw;
  z-index: 2;
  position: relative;

  /* extraWide viewport */
  @media only screen and (min-width: 1640px) {
    margin: auto 120px;
    margin-top: 58px;
  }

  @media only screen and (max-width: 767px) {
    top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    width: 100%;
  }
`;

const Logo = styled.a`
  font-family: Italiana;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.07);

  &:hover {
    text-decoration: none;
    color: black;
    color: white;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    color: white;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
`;

const SpanBtn = styled.span`
  margin: auto;
`;

export default About;
