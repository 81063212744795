import React from "react";
import styled from "styled-components";

// Accessible component for an "In Progress" page
const InProgressPage = () => {
  return (
    <PageWrapper>
      <div className="in-progress-page">
        <header>
          <h1 id="in-progress-header">Work in Progress</h1>
        </header>

        <section
          aria-labelledby="in-progress-header"
          className="in-progress-content"
        >
          <p>Oh oh ! This page is currently under construction.</p>
          <p>Please check back later for updates.</p>

          <div
            role="progressbar"
            aria-live="polite"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow="60"
            className="progress-bar"
          >
            {/* <span className="progress-text">60% Complete</span> */}
          </div>
          {/* 
          <p>
            You can still browse other sections of the site, but some features
            may not be available.
          </p> */}

          {/* <button
            onClick={() => alert("You will be notified once the page is live!")}
            aria-label="Notify me when available"
            className="notify-button"
          >
            Notify Me
          </button> */}
        </section>
      </div>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  height: 80vh;
`;

export default InProgressPage;
