import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // i18n hook
import { LanguageProvider } from "./contexts/LanguageContext"; // Your LanguageProvider context
import LanguageSwitcher from "./LanguageSwitcher";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import ProjectDetails from "./ProjectDetails";
import Contact from "./Contact";
import Hero from "./Hero";
import InProgressPage from "./InProgress";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import Menu from "./Menu";
import styled from "styled-components";
import logoproto from "./assets/images/vscodesportologowhite.png";

const menuSize = 50;

const App = () => {
  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { t } = useTranslation();

  // useEffect(() => {
  //   const scrollPosition = localStorage.getItem("scrollPosition");
  //   if (scrollPosition) {
  //     window.scrollTo(0, parseInt(scrollPosition, 10));
  //   }
  // }, []);

  const { projectId } = useParams();
  const isHome = projectId === undefined; // Check if on the home route

  return (
    <LanguageProvider>
      <Router>
        <AppWrapper>
          <Header role="banner">
            <nav aria-label={t("navigation.main.en")}>
              <Menu size={menuSize} aria-controls="menu" />
            </nav>
            <Logo href="/" aria-label={t("logo.homepage.en")} translate="no">
              vscodes <LogoIcon src={logoproto} alt={t("logo.alt.en")} />
            </Logo>
            {/* <LanguageSwitcher /> */}
          </Header>

          <main id="main-content" role="main" tabIndex="-1">
            <ComponentContainer>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Projects />} />
                <Route
                  path="/portfolio/:projectId"
                  element={<ProjectDetails />}
                />
                <Route path="/fullstack" element={<InProgressPage />} />
                <Route path="/digital-media" element={<InProgressPage />} />
                <Route path="/communication" element={<InProgressPage />} />
                <Route path="/community" element={<InProgressPage />} />
                <Route
                  path="/diversity-inclusion-and-a11y"
                  element={<InProgressPage />}
                />
                <Route path="/tech-sociology" element={<InProgressPage />} />

                {/* 
            <Route path="/services" component={Services} />
            <Route path="/testimonials" component={Testimonials} /> 
            */}
                <Route path="/contact" element={<Contact />} />
                {/*<Route path="*" element={<Error />} />  */}
              </Routes>
            </ComponentContainer>
          </main>
          <Footer />
        </AppWrapper>
      </Router>
    </LanguageProvider>
  );
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* Ensures the page takes up at least the full viewport height */
  /* min-height: 100vh; */
`;

const ComponentContainer = styled.div`
  margin: auto;
  max-width: 330px;
  overflow: hidden;

  @media only screen and (min-width: 800px) {
    max-width: 700px;
  }
`;

const Header = styled.header`
  position: fixed;
  /* width: auto; */
  height: 50px;
  display: flex;
  flex-direction: row;
  z-index: 20;
  margin: 0;

  @media only screen and (min-width: 800px) {
    /* position: absolute; */
    /* margin: 0; */
    /* gap: 10px; */
    /* height: 100px; */
    /* padding-left: 20px; */
  }
`;

const Logo = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Anaheim", serif;
  padding: 4px 12px;
  border-radius: 30px;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.05);
  margin: auto;
  margin-left: ${menuSize + 55}px;
  gap: 10px;

  /* position: relative; */

  &:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 1272px) {
    font-size: 18px;
    /* margin-left: 0; */
    align-items: center;
  }

  @media only screen and (min-width: 800px) {
    margin-left: ${menuSize + 10}px;
  }
`;

const LogoIcon = styled.img`
  height: 16px;
  width: auto;
  /* align-items: center; */

  @media only screen and (min-width: 800px) {
    width: 40px;
    height: auto;
  }
`;

export default App;
