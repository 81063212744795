import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import holdingSmartphone from "./assets/images/holdingSmartphone.png";
import avatar from "./assets/images/avatar.png";
import sendIcon from "./assets/images/sendIcon.png";
import longArmSmallRes from "./assets/images/longArmSmallRes.png";
import { NavLink } from "react-router-dom";
import TextBubbles from "./TextBubbles";

const mkAnimation = (to) => keyframes`
  to {
    left: ${to}vw;
  }
`;

const MessagesContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  overflow: hidden;
`;

const Message = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0;
`;

const Text = styled.h2`
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: normal;
  z-index: 3;

  @media only screen and (max-width: 1200px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 2000px) {
    line-height: 10vh;
    font-size: 74px;
  }
`;

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const messagesRef = useRef([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, subject, message }),
      });

      const data = await response.json();
      console.log(data.message); // Server response message
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Scroll to the top of the page when the component mounts or 'page' state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const anime = (() => {
  //     const t = {
  //       easeInOutQuad: (t, b, c, d) => {
  //         t /= d / 2;
  //         if (t < 1) return (c / 2) * t * t + b;
  //         t--;
  //         return (-c / 2) * (t * (t - 2) - 1) + b;
  //       },
  //     };

  //     return {
  //       animate: (el, prop, from, to, duration, easing) => {
  //         let start;
  //         const ease = t[easing];

  //         const step = (timestamp) => {
  //           if (!start) start = timestamp;
  //           const progress = timestamp - start;
  //           const value = ease(progress, from, to - from, duration);

  //           el.style[prop] = value;

  //           if (progress < duration) {
  //             requestAnimationFrame(step);
  //           } else {
  //             el.style[prop] = to;
  //           }
  //         };

  //         requestAnimationFrame(step);
  //       },
  //     };
  //   })();

  //   const animateMessage = (index) => {
  //     if (index >= messages.length) return;
  //     const messageDiv = messagesRef.current[index];
  //     anime.animate(messageDiv, "opacity", 0, 1, 2000, "easeInOutQuad");
  //     setTimeout(() => {
  //       anime.animate(messageDiv, "opacity", 1, 0, 2000, "easeInOutQuad");
  //       setTimeout(() => {
  //         setCurrentMessageIndex(
  //           (prevIndex) => (prevIndex + 1) % messages.length
  //         );
  //         animateMessage(index + 1);
  //       }, 2000); // Adjust this delay for the duration each message stays visible
  //     }, 2000); // Adjust this delay for the duration each message stays visible
  //   };

  //   animateMessage(currentMessageIndex);
  // }, [currentMessageIndex, messages]);

  return (
    <PageWrapper className="component_wrapper">
      {/* <NavLink
        to="/"
        role="navigation"
        aria-label="Navigate to Home Page"
      ></NavLink> */}
      <HeaderDiv role="region" tabIndex="0">
        <h1>Ready, Set, Connect</h1>
        <AvatarImg
          src={avatar}
          alt="Contact avatar of a black woman with an afro behind a laptop"
        />
      </HeaderDiv>
      <BlobContainer>
        <div className="shape-blob"></div>
        <div className="shape-blob one"></div>
        <div className="shape-blob two"></div>
      </BlobContainer>

      <Section1>
        {/* <Text> */}
        {/* <Messages /> Integrated Messages component here */}
        <TextBubbles />
        {/* </Text> */}
        {/* <Text>
          If you're up for game-changing collabs, top-notch consulting, or
          exciting job prospects...
        </Text> */}
        {/* <HandImg src={holdingSmartphone} /> */}
        {/* <SlideText>slide into my DMs ↴</SlideText> */}
        <HandImg
          src={longArmSmallRes}
          alt="Representation of an arm holding a phone"
        />
      </Section1>

      <FormWrapper>
        <Form onSubmit={handleSubmit} role="form">
          <FormLabel>
            {/* <p class="visuallyhidden">name</p> */}
            <Input
              type="text"
              placeholder="Name"
              value={name}
              required={true}
              onChange={handleNameChange}
              autoComplete="name"
              for="name"
              aria-label="name"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">email</p> */}
            <Input
              type="email"
              placeholder="Email"
              required={true}
              value={email}
              onChange={handleEmailChange}
              autoComplete="email"
              for="email"
              aria-label="Email"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">subject</p> */}
            <Input
              type="text"
              placeholder="Subject"
              required={true}
              value={subject}
              onChange={handleSubjectChange}
              for="subject"
              aria-label="subject"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">message</p> */}
            <TextArea
              value={message}
              placeholder="Message"
              required={true}
              maxLength={500}
              onChange={handleMessageChange}
              for="message"
              aria-label="message"
            />
          </FormLabel>
          <br />
          <DivButton>
            <button type="submit" aria-label="submit">
              <SendImg src={sendIcon} alt="submit icon" />{" "}
            </button>
          </DivButton>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};

const BlobContainer = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: -1;
`;

const PageWrapper = styled.div`
  margin-top: 100px;

  @media screen and (min-width: 2000px) {
    /* max-width: 2000px; */
  }
  @media screen and (min-width: 800px) {
  }

  button {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .shape-blob {
    background: linear-gradient(#b63f95, #ec6a42, #92f8dd);
    height: 600px;
    width: 600px;
    border-radius: 30% 50% 20% 40%;
    animation: border-radius 20s ease-in-out infinite both alternate,
      movement_two 40s ease-in-out infinite both;
    opacity: 0.9;
    z-index: -1;
    filter: blur(3rem);
    position: absolute;
    left: 20%;
    top: 50%;
    will-change: transform;
  }

  .shape-blob.one {
    height: 500px;
    width: 500px;
    left: -100px;
    top: -50px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
  }

  .shape-blob.two {
    height: 1000px;
    width: 1000px;
    left: 500px;
    top: 150px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;

    @media only screen and (max-width: 767px) {
      height: 500px;
      width: 500px;
    }

    @media only screen and (min-width: 2000px) {
      height: 1500px;
      width: 1500px;
      left: 500;
      top: 20%;
    }
  }

  @keyframes border-radius {
    0%,
    100% {
      border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
    }
    20% {
      border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
    }
    40% {
      border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
    }
    60% {
      border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
    }
    80% {
      border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
    }
  }

  @keyframes movement_two {
    0%,
    500% {
      transform: none;
    }
    50% {
      transform: translate(50%, 20%) rotate(-200deg) scale(1.5);
    }
  }
`;

const Section1 = styled.div`
  border-radius: 44px;
  /* background: rgba(0, 0, 0, 0.85); */
  position: relative;

  z-index: 2;
  margin-bottom: 24px;
  height: 400px;
  font-size: var(--font-large);
  font-weight: 300;

  @media only screen and (min-width: 800px) {
    /* height: calc(1000px - 150px); */
    font-size: var(--font-xlarge);
  }
`;

const AvatarImg = styled.img`
  margin-top: 30px;
  height: 50px;
  width: 50px;
  border: 1px white;
  border-radius: 50%;
  background-color: white;
  object-fit: scale-down;

  @media only screen and (min-width: 800px) {
    height: 60px;
    width: 60px;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  h1 {
    font-size: 26px;

    @media only screen and (min-width: 800px) {
      font-size: 60px;
    }
  }
`;

const HandImg = styled.img`
  height: auto;
  display: none;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  z-index: 3;
  left: -100%;
  pointer-events: none;
  width: 65vw;
  animation-name: ${mkAnimation(-25)};
  top: 300px;

  /* @media only screen and (min-width: 2000px) {
    width: 1100px;
    animation-name: ${mkAnimation(-18)};
    top: 45%;
  }

  @media only screen and (max-width: 1200px) {
    width: 500px;
    animation-name: ${mkAnimation(-17)};
  }

  @media only screen and (max-width: 767px) {
    width: 350px;
    animation-name: ${mkAnimation(-10)};
  }

   */

  @media only screen and (min-width: 800px) {
    animation-name: ${mkAnimation(-15)};
    width: 40vw;
  }
`;

const SlideText = styled.h2`
  flex-shrink: 0;
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  right: 50px;
  margin-top: 100px;
  z-index: 3;

  @media only screen and (min-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 2000px) {
    font-size: 74px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 2000px) {
    font-size: 20px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormLabel = styled.label`
  border-radius: 44px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  z-index: 1;
  /* TO DO : find out why no margin bottom in firefox */
  margin-bottom: 1vh;

  &:focus-within {
    box-shadow: 0 0 0 1px #92f8dd;
  }

  ::placeholder {
    color: white;
    opacity: 1;
  }

  @media only screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const Input = styled.input`
  background: none;
  padding: 30px;
  border: none;
  color: white;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const TextArea = styled.textarea`
  background: transparent;
  padding: 30px;
  border: none;
  margin: auto;
  color: white;
  height: 15svh;
  min-height: 15vh;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const SendImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

const DivButton = styled.div`
  margin: 20px auto;
`;

const LogoDiv = styled.div`
  top: -10px;
  left: 7vw;
  z-index: 2;
  position: relative;

  /* extraWide viewport */
  @media only screen and (min-width: 1640px) {
    /* margin: auto 120px; */
    /* margin-top: 58px; */
  }

  @media only screen and (max-width: 767px) {
    /* top: 20px; */
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    width: 100%;
  }
`;

const Logo = styled.a`
  font-family: Italiana;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.07);

  &:hover {
    text-decoration: none;
    color: black;
    color: white;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    color: white;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
`;

export default Contact;
