import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { TiThMenu } from "react-icons/ti";

const Menu = ({ size }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuIconRef = useRef(null);
  const divNavRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const closeMenu = () => {
    setShowMenu(false);
    menuIconRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault(); // Prevent default spacebar scrolling
      toggleMenu();
    }
    if (e.key === "Escape" && showMenu) {
      closeMenu();
    }
  };

  useEffect(() => {
    const handleFocus = (event) => {
      if (showMenu) {
        const focusableElements = [divNavRef.current, menuIconRef.current];
        if (!focusableElements.some((el) => el && el.contains(event.target))) {
          divNavRef.current.focus(); // Redirect focus back to the menu
        }
      }
    };

    if (showMenu) {
      document.body.style.pointerEvents = "none"; // Disable pointer events for background elements
      divNavRef.current.focus(); // Initial focus to the menu
      document.addEventListener("focusin", handleFocus); // Listen for focus changes
    } else {
      document.body.style.pointerEvents = ""; // Re-enable pointer events
      document.removeEventListener("focusin", handleFocus); // Clean up event listener
    }

    return () => {
      document.body.style.pointerEvents = ""; // Clean up styles
      document.removeEventListener("focusin", handleFocus); // Clean up event listener
    };
  }, [showMenu]);

  return (
    <>
      <MenuDiv size={size}>
        <DivNavSections
          showMenu={showMenu}
          ref={divNavRef}
          tabIndex={showMenu ? "0" : "-1"}
          aria-hidden={!showMenu}
          style={{ display: showMenu ? "flex" : "none" }}
        >
          <AnchorLink href="/" onClick={closeMenu}>
            Home
          </AnchorLink>
          <AnchorLink href="/about" onClick={closeMenu}>
            About
          </AnchorLink>
          <AnchorLink href="/contact" onClick={closeMenu}>
            Contact
          </AnchorLink>
        </DivNavSections>
        <MenuIconWrapper
          onClick={toggleMenu}
          onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
          tabIndex="0" // Ensure focusability
          ref={menuIconRef}
          role="button"
          aria-expanded={showMenu ? "true" : "false"}
          aria-controls="menu"
          aria-label={showMenu ? "Close Menu" : "Open Menu"}
        >
          {showMenu ? <CloseIcon>✖</CloseIcon> : <TiThMenu size={30} />}
        </MenuIconWrapper>
      </MenuDiv>
    </>
  );
};

const MenuDiv = styled.div`
  /* position: fixed; */
  right: 10px;
  bottom: 10px;

  @media only screen and (min-width: 1000px) {
    bottom: auto;
    right: auto;
  }
`;

const MenuIcon = styled.img`
  @media only screen and (min-width: 1000px) {
  }
`;

const MenuIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1000;
  position: fixed;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.05);
  &:hover {
    background: rgba(0, 0, 0, 0.1); /* Slight hover effect */
    color: yellow;
    outline: 2px solid #fffc00;
  }
  /* Ensure the MenuIconWrapper remains clickable */
  pointer-events: auto;

  @media only screen and (min-width: 1000px) {
    /* position: fixed; */
  }
`;

const DivNavSections = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.8); */
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transform: ${({ showMenu }) =>
    showMenu ? "translateX(0)" : "translateX(-100%)"};
  z-index: 999;
  /* width: ${({ showMenu }) => (showMenu ? "100vw" : "0")};
  height: ${({ showMenu }) => (showMenu ? "100vh" : "0")}; */

  /* overflow: hidden; */
  background-size: cover;
  /* transition: width 0.5s, height 0.5s; */
  background-image: url("https://images.unsplash.com/photo-1579547944212-c4f4961a8dd8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3039&q=80");
  /* Enable pointer events for links when menu is shown */
  pointer-events: ${({ showMenu }) => (showMenu ? "auto" : "none")};
`;

const AnchorLink = styled.a`
  font-size: 50px;
  text-decoration: none;
  padding: 50px 0;
  border-radius: 30px;
  display: flex;
  font-weight: 400;
  justify-content: space-evenly;
  /* Hover state - when the mouse hovers over the element */
  &:hover,
  &:focus {
    text-decoration: none;
    background: rgba(217, 217, 217, 0.07);
    color: #fffc00;
    outline: 2px solid #fffc00; /* You can remove the default focus outline, but make sure your custom focus is clear */
  }

  /* Active state - when the element is clicked or focused */
  &.active,
  &:focus {
    color: #fffc00;
  }

  /* Optionally, add a focus ring for better accessibility */
  &:focus-visible {
    outline: 2px solid #fffc00; /* Custom outline for focus-visible, visible only when keyboard navigation */
  }
`;

const CloseIcon = styled.span`
  font-size: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: auto;
  letter-spacing: 0;
`;

export default Menu;
